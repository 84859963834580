import { REQUIRED_ERROR } from "utils/errorMessages";
import { FORM_TYPES } from "../../../../../shared/generatedForm/GeneratedForm";
import dayjs from "dayjs";

export const TextFields = [
  {
    props: {
      label: "Consulting Type",
      id: "consultingType",
      name: "consultingType",
      size: "small",
    },
    validationProps: {
      required: REQUIRED_ERROR("Consulting Type"),
    },
    type: FORM_TYPES.SelectAPI,
    endpoint: "facilityConsultingTypesBox",
    size: 4,
  },

  {
    props: {
      name: "timeIn",
      id: "timeIn",
      label: "Time In",
      placeholder: "Time In ",
      size: "small",
      type: "time",
    },
    validationProps: {
      required: REQUIRED_ERROR("Time In"),
      validate: (value, formValues) =>
        dayjs(value).diff(dayjs(formValues.timeOut), "minutes") == 0
          ? "In time and Out time should be different."
          : null,
    },
    type: FORM_TYPES.TimePicker,
    size: 2.5,
  },
  {
    props: {
      name: "timeOut",
      id: "timeOut",
      label: "Time Out",
      placeholder: "Time Out ",
      size: "small",
      type: "time",
    },
    validationProps: {
      required: REQUIRED_ERROR("Time Out"),
      validate: (value, formValues) =>
        dayjs(value).diff(dayjs(formValues.timeIn), "minutes") == 0
          ? "In time and Out time should be different."
          : null,
    },
    type: FORM_TYPES.TimePicker,

    size: 2.5,
  },
  {
    props: {
      name: "greaterThanTenOffset",
    },
    size: 6.5,
    type: FORM_TYPES.Offset,
  },
  {
    props: {
      name: "greaterThanTen",
      id: "greaterThanTen",
      text: "Time entered exceeds 10 hours",
      fontSize: "14px",
      size: "small",
      sx: { color: "#DE6D00" },
    },
    type: FORM_TYPES.Text,
    size: 3,
  },
  {
    props: {
      name: "mileage",
      id: "mileage",
      label: "Mileage",
      size: "small",

      type: "number",
    },
    validationProps: {
      required: REQUIRED_ERROR("mileage"),
      min: {
        value: 0,
        message: "Please Enter a Positive value ",
      },
      maxLength: {
        value: 4,
        message: "Please enter a valid four-digit number or less",
      },
    },

    size: 2,
  },
  {
    props: {
      name: "notes",
      id: "notes",
      label: "Notes",
      multiline: true,
      rows: 2,
    },

    size: 10,
  },
];

export const ConsultantFields = [
  // {
  //   props: {
  //     name: "facilityId",
  //     id: "facilityId",
  //     label: "Community",
  //   },
  //   validationProps: {},
  //   size: 8,
  //   type: FORM_TYPES.AutocompleteAPI,
  //   endpoint: "getFacilitySelectBox",
  // },
  {
    props: {
      name: "scheduled",
      id: "scheduled",
      label: "Scheduled Visit",
    },
    validationProps: {},
    size: 6,
    type: FORM_TYPES.Checkbox,
  },
  {
    props: {
      name: "substituteRD",
      id: "substituteRD",
      label: "Substitute Consultant",
    },
    validationProps: {},
    size: 6,
    type: FORM_TYPES.Checkbox,
  },
  {
    props: {
      name: "dateOfVisit",
      id: "dateOfVisit",
      label: "Date Of Visit",
      placeholder: "Date Of Visit",
    },
    validationProps: {},
    type: FORM_TYPES.DatePicker,
    size: 8,
  },

  {
    props: {
      name: "nextConsult",
      id: "nextConsult",
      label: "Future Consultant Date",
      placeholder: "Future Consultant Date ",
      disablePast: true,
    },
    validationProps: {
      min: {
        value: new Date(),
        message: "Date Must be in Future",
      },
      required: "Future Consultant Date is required unless N/A is checked",
    },

    type: FORM_TYPES.DatePicker,
    size: 8,
  },
  {
    props: {
      name: "nextConsultNA",
      id: "nextConsultNA",
      label: "Not Assigned for next visit",
    },
    validationProps: {},
    size: 8,
    type: FORM_TYPES.Checkbox,
  },
  {
    props: {
      name: "nextVisitNotes",
      id: "nextVisitNotes",
      label: "Notes",
      multiline: true,
      rows: 4,
    },
    validationProps: {
      required:
        "If not assign for next visit is checked, you must also enter a reason in Notes",
    },
    size: 12,
  },
];

export const ConsultantFieldsDefaults = {
  dateOfVisit: new Date(),
  nextVisitNotes: null,
  scheduled: false,
  substituteRD: false,
  isFinal: false,
  isFinalDate: new Date(),
  hasBeenExported: false,
  hasBeenExportedToSage: false,
  isCDMVisit: false,
  isVisitFinished: false,
  dateFinished: new Date(),
  nextConsultNA: false,
  totalBillableHour: 0,
  planDate: new Date(),
};

import React from "react";
import { Box } from "@mui/material";
import { BackButton } from "components/shared/BackButton";
import { BaseButton } from "components/shared/BaseButton";
import { useSearchParams } from "react-router-dom";
import { pcpFax_ScreenNames } from "../constants";
import HeaderBox from "components/consultant/shared/HeaderBox";
import BaseDataGrid from "components/shared/baseDataGrid/BaseDataGrid";
import { EditButton } from "components/shared/EditButton";
import DeleteButton from "components/shared/DeleteButton";
import YesNoDisplay from "components/shared/YesNoDisplay";

export const ManageDoctor = () => {
  const [searchParams] = useSearchParams();
  const visitPlanId = searchParams.get("visitPlanId");
  const visitReportId = searchParams.get("visitReportId");
  const reportId = searchParams.get("id");

  const columns = [
    {
      field: "name",
      //   minWidth: 125,
      flex: 1,
      headerName: "Name",
    },
    {
      field: "officeName",
      //   minWidth: 170,
      flex: 1,
      headerName: "Office Name",
    },

    {
      field: "faxNumber",
      //   minWidth: 170,
      flex: 1,
      headerName: `Fax Number`,
      cellClassName: "cellCenterAlign",
    },
    {
      field: "active",
      minWidth: 100,
      flex: 1,
      headerName: `Active`,
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => (
        <YesNoDisplay value={params?.row?.changeToTrayCardNeeded} />
      ),
    },

    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      maxWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <EditButton
              index={params.row.id}
              //   To={`/Consultant/MyRosters/VisitDetails/AddResidentAssessment?visitPlanId=${visitPlanId}&id=${params.row.id}&visitReportId=${visitReportId}`}
              // data={MenusData}
              title="Menu"
            />
            <DeleteButton
              index={params.row.id}
              entityName={params.row?.residentName}
              apiPath="deleteNutritionalCare"
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Box padding={"8px"}>
        <BackButton To={-1} iconDisabled={true} sx={{ marginBottom: "10px" }} />
        <HeaderBox
          visitPlanId={visitPlanId}
          ScreensArray={pcpFax_ScreenNames}
        />
        <Box
          marginTop={"10px"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}>
          <BaseButton
            To={`/Consultant/MyRosters/VisitDetails/AddDoctors?visitPlanId=${visitPlanId}&visitReportId=${visitReportId}&id=${reportId}`}
            marginLeft={"0px !important"}
            text={"Add Doctor"}
            endIcon={"add"}
          />
        </Box>
        <Box marginTop={"10px"}>
          <BaseDataGrid
            rows={[]}
            //   loading={isLoading}
            columns={columns}
            //   error={error}
            autoHeight={true}
          />
        </Box>
      </Box>
    </>
  );
};

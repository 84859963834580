import { Box, Grid } from "@mui/material";
import HeaderBox from "components/consultant/shared/HeaderBox";
import { BackButton } from "components/shared/BackButton";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { PCPFAX_FIELDS, pcpFax_ScreenNames } from "./constants";
import { BaseButton } from "components/shared/BaseButton";
import BaseSubmitButton from "components/shared/baseSubmitButton/BaseSubmitButton";
import { useForm } from "react-hook-form";
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import { useCreateMDFAXMutation } from "store/apis/NutritionalCareAPIs";
import { useSelector } from "react-redux";
import { selectFacilityId } from "store/slices/authSlice/AuthSlice";

const PcpFax = () => {
  const [searchParams] = useSearchParams();
  const visitPlanId = searchParams.get("visitPlanId");
  const reportId = searchParams.get("id");
  const visitReportId = searchParams.get("visitReportId");
  const facilityId = useSelector(selectFacilityId);

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  // APi Section
  const [createFax, { isLoading }] = useCreateMDFAXMutation();

  const onSubmit = (data) => {
    const dataSent = {
      reportId: reportId,
      visitReportId: visitReportId,
      facilityId: facilityId,
      office: "",
      doctor: "",
      toFAXNumber: "",

      ...data,
    };
    createFax(dataSent);
  };
  return (
    <>
      <Box padding={"8px"}>
        <BackButton To={-1} iconDisabled={true} sx={{ marginBottom: "10px" }} />
        <HeaderBox
          visitPlanId={visitPlanId}
          ScreensArray={pcpFax_ScreenNames}
        />
        <Box
          marginTop={"10px"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}>
          <BaseButton
            marginLeft={"0px !important"}
            To={`/Consultant/MyRosters/VisitDetails/FacilityDoctors?visitPlanId=${visitPlanId}&visitReportId=${visitReportId}&id=${reportId}`}
            text={"Manage Doctor"}
          />
          <BaseSubmitButton
            isSubmitting={isLoading}
            onClick={handleSubmit(onSubmit)}
            text="Create Fax"
          />
        </Box>
        <Grid marginTop={"10px"} width={"50%"} container spacing={2}>
          <GeneratedForm
            control={control}
            list={PCPFAX_FIELDS}
            errors={errors}
          />
        </Grid>
      </Box>
    </>
  );
};

export default PcpFax;

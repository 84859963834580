import React, { useEffect, useState } from "react";
import GeneratedForm from "../../../../../../../shared/generatedForm/GeneratedForm";
import {
  RESIDENT_ASSESSMENT_DEFAULTS,
  RESIDENT_ASSESSMENT_FIELDS,
} from "./forms.constants";
import { useForm } from "react-hook-form";
import {
  useAddNutritionalCareMutation,
  useNutritionalCareByIdQuery,
  useUpdateNutritionalCareMutation,
} from "../../../../../../../../store/apis/NutritionalCareAPIs";
import { Box, Grid } from "@mui/material";
import BaseSubmitButton from "../../../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { EditSkeleton } from "../../../../../../../admin/admin.overlays";
import { setErrorsForProperties } from "../../../../../../../shared/utils/helpers";
import HeaderBox from "components/consultant/shared/HeaderBox";
import { Nutrition_ScreenNames } from "../../constants";
import { BackButton } from "components/shared/BackButton";
import BaseInput from "components/shared/baseForm/BaseInput";
import { useGetVisitReportsByIdQuery } from "store/apis/ConsultantApis";
import BaseBlockerModal from "components/shared/blockerModal/BaseBlockerModal";
import { BaseButton } from "components/shared/BaseButton";
import { DMStyles } from "styles/Styles";
import { isEqual } from "lodash";

export default function AddResidentAssessment() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const assessmentId = searchParams.get("id");
  const visitReportId = searchParams.get("visitReportId");
  const visitPlanId = searchParams.get("visitPlanId");
  const [hiddenList, setHiddenList] = useState([]);
  const [validationSummary, setValidationSummary] = useState();
  const [buttonName, setButtonName] = useState();
  // unsaved changes functionality here
  const [isChange, setIsChange] = useState(false);

  const isEdit = !!assessmentId;

  const { data: visitReportData } = useGetVisitReportsByIdQuery(visitReportId, {
    refetchOnMountOrArgChange: true,
  });

  const {
    data: currentAssessment,
    isLoading: currentLoading,
    isSuccess: currentSuccess,
  } = useNutritionalCareByIdQuery(assessmentId, { skip: !assessmentId });

  const isEditLoading = isEdit && currentLoading;

  const [
    updateAssessment,
    { error: addError, isLoading: addLoading, isSuccess: addSuccess },
  ] = useUpdateNutritionalCareMutation();
  const [
    addAssessment,
    {
      data: addAssessmentData,
      error: updateError,
      isLoading: updateLoading,
      isSuccess: updateSuccess,
    },
  ] = useAddNutritionalCareMutation();

  const errorResponse = addError || updateError;
  const isLoading = addLoading || updateLoading;
  const isSuccess = addSuccess || updateSuccess;

  if (isSuccess)
    navigate(
      isEqual(buttonName, "PCP Fax")
        ? `/Consultant/MyRosters/VisitDetails/CreateMDFAX?visitPlanId=${visitPlanId}&visitReportId=${visitReportId}&id=${addAssessmentData?.id}`
        : -1
    );

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
    defaultValues: RESIDENT_ASSESSMENT_DEFAULTS,
  });

  const {
    control,
    setError,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isDirty },
  } = methods;

  useEffect(() => {
    setIsChange(isDirty);
  }, [isDirty]);

  const onSubmit = (data, buttonName) => {
    console.log("🚀 ~ onSubmit ~ data:", data);
    setIsChange(false);
    if (isEdit) {
      updateAssessment(data);
      return;
    }
    data[`visitReportId`] = visitReportId;
    addAssessment({ ...data, submitButton: buttonName });
  };

  useEffect(() => {
    if (!currentLoading && currentSuccess) {
      if (currentAssessment?.subTypes === null) {
        reset({
          ...currentAssessment,
          subTypes: [],
          description: visitReportData?.description,
        });
      } else {
        reset({
          ...currentAssessment,
          description: visitReportData?.description,
        });
      }

      showInputs(currentAssessment);
    }
    reset({
      description: visitReportData?.description,
    });
  }, [currentLoading, visitReportData, currentSuccess]);

  useEffect(() => {
    if (errorResponse) {
      setErrorsForProperties({
        setError: setError,
        responseError: errorResponse,
        setValidationSummary: setValidationSummary,
      });
    }
  }, [errorResponse]);

  const assessmentTypeId = watch("assessmentTypeId");
  const otherNeeded = watch("otherNeeded");
  const changeToCarePlanNeeded = watch("changeToCarePlanNeeded");
  const changeToTrayCardNeeded = watch("changeToTrayCardNeeded");
  const dietOrderChangeNeeded = watch("dietOrderChangeNeeded");

  function showInputs(data) {
    // Hide SubTypes Field if asessement type is not 6
    if ((data?.assessmentTypeId || assessmentTypeId) !== 6) {
      setHiddenList((prevHiddenList) => [
        ...prevHiddenList,
        { name: "subTypes" },
        { name: "subTypesOffset" },
      ]);
    } else {
      setHiddenList((prevHiddenList) =>
        prevHiddenList.filter(
          (x) => x.name !== "subTypes" && x.name !== "subTypesOffset"
        )
      );
    }

    // Hide OtherComment if Other Needed is not checked
    if (!(data?.otherNeeded || otherNeeded))
      setHiddenList((prevHiddenList) => [
        ...prevHiddenList,
        { name: "otherComment" },
        { name: "otherCommentOffset" },
      ]);
    else
      setHiddenList((prevHiddenList) =>
        prevHiddenList.filter(
          (x) => x.name !== "otherComment" && x.name !== "otherCommentOffset"
        )
      );

    // Hide Change To Care Plan Comment if not checked
    if (!(data?.changeToCarePlanNeeded || changeToCarePlanNeeded))
      setHiddenList((prevHiddenList) => [
        ...prevHiddenList,
        { name: "changeToCarePlanComment" },
        { name: "changeToCarePlanCommentOffset" },
      ]);
    else
      setHiddenList((prevHiddenList) =>
        prevHiddenList.filter(
          (x) =>
            x.name !== "changeToCarePlanComment" &&
            x.name !== "changeToCarePlanCommentOffset"
        )
      );

    // Hide Change to MealCard Comment if not checked
    if (!(data?.changeToTrayCardNeeded || changeToTrayCardNeeded))
      setHiddenList((prevHiddenList) => [
        ...prevHiddenList,
        { name: "changeToTrayCardComment" },
        { name: "changeToTrayCardCommentOffset" },
      ]);
    else
      setHiddenList((prevHiddenList) =>
        prevHiddenList.filter(
          (x) =>
            x.name !== "changeToTrayCardComment" &&
            x.name !== "changeToTrayCardCommentOffset"
        )
      );

    // Hide Diet Order Change Needed Comment if not checked
    if (!(data?.dietOrderChangeNeeded || dietOrderChangeNeeded))
      setHiddenList((prevHiddenList) => [
        ...prevHiddenList,
        { name: "newDiet" },
        { name: "newDietOffset" },
      ]);
    else
      setHiddenList((prevHiddenList) =>
        prevHiddenList.filter(
          (x) => x.name !== "newDiet" && x.name !== "newDietOffset"
        )
      );
  }

  useEffect(() => {
    showInputs();
  }, [
    assessmentTypeId,
    otherNeeded,
    changeToCarePlanNeeded,
    changeToTrayCardNeeded,
    dietOrderChangeNeeded,
  ]);

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton text={"Loading Resident Assessment Information."} />
      ) : (
        <>
          {" "}
          <BaseBlockerModal isDirty={isChange} />
          <Box padding={"8px"}>
            <BackButton
              To={-1}
              iconDisabled={true}
              sx={{ marginBottom: "10px" }}
            />
            <HeaderBox
              visitPlanId={visitPlanId}
              ScreensArray={Nutrition_ScreenNames}
            />
            <Box
              marginY={"15px"}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}>
              <Box width={"30%"}>
                <BaseInput
                  name="description"
                  id="description"
                  label="Description"
                  size="small"
                  control={control}
                  errors={errors}
                  disabled
                />
              </Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}>
                <BaseButton
                  sx={{
                    color: DMStyles.Teal,
                    marginRight: "10px",
                  }}
                  border={`1px solid ${DMStyles.Teal}`}
                  variant="outline"
                  text="PCP Fax"
                  onClick={() => {
                    setButtonName("PCP Fax");
                    handleSubmit((data) => onSubmit(data, "PCP Fax"))();
                  }}
                  isSubmitting={
                    isEqual(buttonName, "PCP Fax") ? isLoading : false
                  }
                  validationSummary={validationSummary}
                />

                <BaseSubmitButton
                  onClick={handleSubmit((data) => onSubmit(data, "save"))}
                  isSubmitting={isEqual(buttonName, "save") ? isLoading : false}
                  validationSummary={validationSummary}
                />
              </Box>
            </Box>
            <Grid marginBottom={"8px"} container spacing={2}>
              <GeneratedForm
                control={control}
                list={RESIDENT_ASSESSMENT_FIELDS}
                hiddenList={hiddenList}
                errors={errors}
                queries={[
                  {
                    name: "subTypes",
                    query: {
                      assessmentTypeId: assessmentTypeId,
                    },
                    skip: !assessmentTypeId,
                  },
                ]}
              />
            </Grid>
          </Box>
        </>
      )}
    </>
  );
}

import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { NUTRITIONAL_CARES } = API_ENDPOINTS;

export const NutritionalCareAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    nutritionalCareById: builder.query({
      query: (id) => `${NUTRITIONAL_CARES}/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [{ type: NUTRITIONAL_CARES, id: response.id }]
          : [NUTRITIONAL_CARES];
      },
    }),
    nutritionalCares: builder.query({
      query: (visitReportId) => ({
        url: `/${NUTRITIONAL_CARES}?visitReportId=${visitReportId}`,
      }),
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [
              ...response.map(({ id }) => ({ type: NUTRITIONAL_CARES, id })),
              NUTRITIONAL_CARES,
            ]
          : [NUTRITIONAL_CARES],
    }),
    deleteNutritionalCare: builder.mutation({
      query: (id) => ({
        url: `/${NUTRITIONAL_CARES}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: NUTRITIONAL_CARES, id: arg.id },
      ],
      transformResponse: (response) => response?.data,
    }),
    addNutritionalCare: builder.mutation({
      query: (data) => ({
        url: `/${NUTRITIONAL_CARES}`,
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: (result, error, arg) => [
        { type: NUTRITIONAL_CARES, id: arg.id },
      ],
    }),

    updateNutritionalCare: builder.mutation({
      query: (data) => ({
        url: `/${NUTRITIONAL_CARES}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: NUTRITIONAL_CARES, id: arg.id },
      ],
    }),
    createMDFAX: builder.mutation({
      query: (data) => ({
        url: `/${NUTRITIONAL_CARES}/CreateMDFAX`,
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: (result, error, arg) => [
        { type: NUTRITIONAL_CARES, id: arg.id },
      ],
    }),
    insertFacilityDoctor: builder.mutation({
      query: (data) => ({
        url: `/${NUTRITIONAL_CARES}/InsertFacilityDoctor`,
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: (result, error, arg) => [
        { type: NUTRITIONAL_CARES, id: arg.id },
      ],
    }),
    updateFacilityDoctor: builder.mutation({
      query: (data) => ({
        url: `/${NUTRITIONAL_CARES}/UpdateFacilityDoctor`,
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: (result, error, arg) => [
        { type: NUTRITIONAL_CARES, id: arg.id },
      ],
    }),
    possibleAssessmentTypes: builder.query({
      query: () => ({
        url: `/${NUTRITIONAL_CARES}/possibleAssessmentTypes`,
      }),
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
      keepUnusedDataFor: 60,
    }),
    possibleSubTypes: builder.query({
      query: ({ assessmentTypeId }) => ({
        url: `/${NUTRITIONAL_CARES}/possibleSubTypes?assessmentTypeId=${assessmentTypeId}`,
      }),
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
      keepUnusedDataFor: 60,
    }),
    possibleResidentTypes: builder.query({
      query: () => ({
        url: `/${NUTRITIONAL_CARES}/possibleResidentTypes`,
      }),
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
      keepUnusedDataFor: 60,
    }),
  }),
});

export const {
  useNutritionalCaresQuery,
  useDeleteNutritionalCareMutation,
  useAddNutritionalCareMutation,
  useCreateMDFAXMutation,
  useInsertFacilityDoctorMutation,
  useUpdateFacilityDoctorMutation,
  useUpdateNutritionalCareMutation,
  usePossibleAssessmentTypesQuery,
  usePossibleSubTypesQuery,
  usePossibleResidentTypesQuery,
  useNutritionalCareByIdQuery,
} = NutritionalCareAPIs;

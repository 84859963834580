import { Box, Grid } from "@mui/material";
import HeaderBox from "components/consultant/shared/HeaderBox";
import { BackButton } from "components/shared/BackButton";
import { BaseButton } from "components/shared/BaseButton";
import BaseSubmitButton from "components/shared/baseSubmitButton/BaseSubmitButton";
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { ADD_DOCTOR_FIELDS, addDoctor_ScreenNames } from "./constants";
import { useForm } from "react-hook-form";
import { useInsertFacilityDoctorMutation } from "store/apis/NutritionalCareAPIs";
import { useSelector } from "react-redux";
import { selectFacilityId } from "store/slices/authSlice/AuthSlice";

export const AddDoctor = () => {
  const [searchParams] = useSearchParams();
  const visitPlanId = searchParams.get("visitPlanId");
  const visitReportId = searchParams.get("visitReportId");
  const reportId = searchParams.get("id");
  const facilityId = useSelector(selectFacilityId);

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  const [addDoctor, { isLoading: addLoading }] =
    useInsertFacilityDoctorMutation();

  const onSubmit = (data) => {
    const dataSent = {
      facilityId,
      id: reportId,
      ...data,
    };
    addDoctor(dataSent);
  };
  return (
    <>
      <Box padding={"8px"}>
        <BackButton To={-1} iconDisabled={true} sx={{ marginBottom: "10px" }} />
        <HeaderBox
          visitPlanId={visitPlanId}
          ScreensArray={addDoctor_ScreenNames}
        />
        <Box
          marginTop={"10px"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}>
          <BaseButton
            marginLeft={"0px !important"}
            text={"Manage Doctor"}
            To={`/Consultant/MyRosters/VisitDetails/FacilityDoctors?visitPlanId=${visitPlanId}&visitReportId=${visitReportId}&id=${reportId}`}
          />
          <BaseSubmitButton
            isSubmitting={addLoading}
            onClick={handleSubmit(onSubmit)}
            text="Save"
          />
        </Box>
        <Grid marginTop={"10px"} width={"50%"} container spacing={2}>
          <GeneratedForm
            control={control}
            list={ADD_DOCTOR_FIELDS}
            errors={errors}
          />
        </Grid>
      </Box>
    </>
  );
};
